import dayjs from 'dayjs';
import client from '../apollo';
import { messageContext } from '../components/AppContextHolder';
import { GET_SIGNED_URL } from '../components/graphql/mutations';
import api from './api';
import {
  COMMON_ALLOWED_FILE_TYPES,
  COMMON_FILE_UPLOAD_TYPES,
  COMPANY_INFO,
  defaultDateFormat,
  REGEX,
} from './constants';

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && dayjs && format) {
    return dayjs(dateTime)?.format(format);
  }

  return dateTime;
};

export const disabledFutureDates = (current) =>
  current && current > dayjs()?.endOf('day');

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid email');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid number');
      }
      return Promise?.resolve();
    },
  }),
  multipleEmails: () => ({
    validator(rule, value) {
      // check length of valid emails
      const validEmailsLength = value?.filter((val) =>
        REGEX?.EMAIL?.test(val?.trim()),
      )?.length;

      if (!value?.length) {
        return Promise?.resolve();
      }
      if (validEmailsLength !== value?.length) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Invalid email');
      }
      return Promise?.resolve();
    },
  }),
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.setRequestHeader('x-amz-acl', 'public-read');
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    messageContext?.error(error?.message);
  }
}

export const getSignedUrl = async (fileObj) => {
  const fileName = fileObj?.name;

  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type,
    },
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isTypeAllowed = COMMON_ALLOWED_FILE_TYPES?.includes(file?.type);
  if (!isTypeAllowed) {
    messageContext?.error('You can only upload JPG/PNG file!');
    return false;
  }
  const isSizeAllowed =
    file?.size / 1024 / 1024 < COMPANY_INFO?.PROFILE_UPLOAD_SIZE;
  if (!isSizeAllowed) {
    messageContext?.error(
      `Image must be smaller than ${COMPANY_INFO?.PROFILE_UPLOAD_SIZE}MB!`,
    );
    return false;
  }
  return isTypeAllowed && isSizeAllowed;
};

export const beforeFileUpload = (file) => {
  const isTypeAllowed = COMMON_FILE_UPLOAD_TYPES?.includes(file?.type);
  if (!isTypeAllowed) {
    messageContext?.error('You can only upload CSV/PDF file!');
    return false;
  }
  // commenting size validation, as there is no restriction.
  // file size validation
  // const isSizeAllowed =
  //   file?.size / 1024 / 1024 < COMPANY_INFO?.FILE_UPLOAD_SIZE;
  // if (!isSizeAllowed) {
  //   messageContext?.error(
  //     `File must be smaller than ${COMPANY_INFO?.FILE_UPLOAD_SIZE}MB!`,
  //   );
  //   return false;
  // }
  return isTypeAllowed;
};

export const handleProtectedNavigation = (allow, callback, path) =>
  allow ? callback(path) : false;

// create cookie
export const setCookie = (name, value, minutes) => {
  let expires = '';
  if (minutes) {
    const date = new Date();
    date?.setTime(date?.getTime() + minutes * 60 * 1000);
    expires = `; expires=${date?.toGMTString()}`;
  }
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=${value}${expires}; path=/`;
};

// retrieve cookie
export const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  let returnCookie = '';
  // eslint-disable-next-line no-undef
  const decodedCookie = decodeURIComponent(document?.cookie);
  const cookieArray = decodedCookie?.split(';');
  if (cookieArray?.length > 0) {
    // eslint-disable-next-line array-callback-return
    cookieArray?.map((item) => {
      let cookie = item;
      while (cookie?.charAt(0) === ' ') {
        cookie = cookie?.substring(1);
      }
      if (cookie?.indexOf(name) === 0) {
        returnCookie = cookie?.substring(name?.length, cookie?.length);
      }
    });
    return returnCookie;
  }
};

// force expired cookie or delete
export const deleteCookie = (name) => {
  // eslint-disable-next-line no-undef
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
