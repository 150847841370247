import {
  AudioOutlined,
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  FilePdfOutlined,
  ImportOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  DatePicker,
  Flex,
  Popconfirm,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { camelCase, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  FILE_TYPES,
  FILTERS,
  LIMIT,
  PROCESS_STATUS,
  SORT_BY,
  SORT_ON,
  defaultDateFormat,
} from '../../common/constants';
import { disabledFutureDates } from '../../common/utils';
import Filter from '../../components/common/CustomTableFIlter';
import ErrorModal from '../../components/common/modals/ErrorModal';
import UploadModal from '../../components/common/modals/UploadModal';
import SearchComponent from '../../components/common/SearchComponent';
import '../index.less';
import { DELETE_ASSET } from './graphql/mutations';
import { GET_ASSETS } from './graphql/queries';

dayjs.extend(utc);

const { Link } = Typography;
const { RangePicker } = DatePicker;

export default function ContentList() {
  const [uploadModal, setUploadModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [isAlertOpen, setIsAlertModal] = useState(false);
  const initialFilters = {
    sort: [
      {
        sortBy: SORT_BY.DESC,
        sortOn: SORT_ON.createdAt,
      },
    ],
    filter: {
      limit: LIMIT,
      skip: 0,
      search: '',
    },
  };
  const [tableParams, setTableParams] = useState(initialFilters);
  const [selectedDate, setSelectedDate] = useState(null);

  const [fetchAssets, { loading, data }] = useLazyQuery(GET_ASSETS, {
    onCompleted: (res) => {
      if (tableParams?.filter?.skip === 0) {
        setDataList(res?.assets?.data || []);
      } else {
        setDataList(
          res?.assets?.data ? [...dataList, ...res?.assets?.data] : [],
        );
      }
    },
    onError: () => {},
    fetchPolicy: 'network-only',
  });

  const [deleteAsset, { loading: deleteLoading }] = useMutation(DELETE_ASSET, {
    onCompleted: () => {
      fetchAssets({
        variables: {
          ...tableParams,
          filter: {
            ...tableParams?.filter,
            limit: LIMIT,
            skip: 0,
          },
        },
        onCompleted: (res) => {
          setDataList(res?.assets?.data || []);
        },
      });
    },
    onError: () => {},
  });

  const filterMiddleware = (isToggle) => {
    if (isToggle) {
      if (tableParams?.filter?.status) {
        return tableParams?.filter?.status?.filter(
          (status) => status !== PROCESS_STATUS?.PROCESSED,
        );
      }
      return FILTERS?.STATUS?.filter(
        ({ value }) => value !== PROCESS_STATUS?.PROCESSED,
      )?.map(({ value }) => value);
    }
    return tableParams?.filter?.status;
  };

  useEffect(() => {
    fetchAssets({
      variables: {
        ...tableParams,
        filter: {
          ...tableParams?.filter,
          status: filterMiddleware(toggle),
        },
      },
    });
  }, [tableParams, toggle]);

  const handleTableChange = (pagination, filters) => {
    const payload = { ...filters };
    delete payload?.createdAt;
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        limit: LIMIT,
        skip: 0,
        status: payload?.status,
        types: payload?.type,
      },
    });
  };

  const LoadMore = () => (
    <div className="d-flex justify-center">
      <Button
        type="link"
        loading={loading}
        icon={<DownOutlined />}
        className="d-flex flex-row-reverse align-center gap-8 justify-center mt-8"
        onClick={() => {
          setTableParams({
            ...tableParams,
            filter: {
              ...tableParams?.filter,
              skip: dataList?.length,
            },
          });
        }}
      >
        Load More
      </Button>
    </div>
  );
  const renderTags = ({ status, reason }) => {
    switch (status) {
      case PROCESS_STATUS?.FAILED:
        return (
          <Tag color="error">
            {startCase(camelCase(PROCESS_STATUS?.FAILED))}
            <InfoCircleOutlined
              className="ml-8 text-error pointer"
              onClick={() => setIsAlertModal(true)}
            />
          </Tag>
        );
      case PROCESS_STATUS?.IN_PROGRESS:
        return (
          <Tag color="processing">
            {startCase(camelCase(PROCESS_STATUS?.IN_PROGRESS))}
          </Tag>
        );
      case PROCESS_STATUS?.PROCESSED:
        return (
          <Tag color="success">
            {startCase(camelCase(PROCESS_STATUS?.PROCESSED))}
          </Tag>
        );
      case PROCESS_STATUS?.UNPROCESSED:
        return (
          <Tag color="default">
            {startCase(camelCase(PROCESS_STATUS?.UNPROCESSED))}
          </Tag>
        );
      case PROCESS_STATUS?.INVALID:
        return (
          <Tag color="warning">
            <Tooltip placement="right" title={reason}>
              <span className="d-flex">
                {PROCESS_STATUS?.INVALID}
                {reason && (
                  <InfoCircleOutlined className="ml-8 text-warning pointer" />
                )}
              </span>
            </Tooltip>
          </Tag>
        );
      default:
        return '-';
    }
  };

  const renderLink = (val, record) => {
    if (record?.url) {
      return (
        <Link href={record?.url} target="_blank">
          {record?.name || record?.url}
        </Link>
      );
    }
    return '-';
  };

  const renderIcons = (type) => {
    switch (type) {
      case FILE_TYPES.AUDIO:
        return <AudioOutlined />;
      case FILE_TYPES.PDF:
        return <FilePdfOutlined />;
      case FILE_TYPES.VIDEO:
        return <VideoCameraOutlined />;
      case FILE_TYPES.WEBSITE:
        return <LinkOutlined />;
      default:
        return '-';
    }
  };

  const handleDateSave = (confirm) => {
    confirm();
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        startDate:
          selectedDate &&
          dayjs(selectedDate?.[0])?.utc(true).startOf('day').format(),
        endDate:
          selectedDate &&
          dayjs(selectedDate?.[1])?.utc(true).endOf('day').format(),
        skip: 0,
      },
    });
  };

  const handleDateReset = (clearFilters, confirm) => {
    confirm();
    clearFilters();
    setSelectedDate(null);
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        startDate: null,
        endDate: null,
        skip: 0,
      },
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'type',
      ellipsis: true,
      render: (type) => <div className="file-icons">{renderIcons(type)}</div>,
    },
    {
      title: 'File Name',
      dataIndex: 'type',
      width: '85%',
      render: renderLink,
      ellipsis: true,
      filteredValue: tableParams?.filter?.types,
      filterDropdown(props) {
        return <Filter filterProps={props} options={FILTERS.FILE_TYPES} />;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: FILTERS?.STATUS,
      width: '5%',
      ellipsis: true,
      render: (val, item) => renderTags(item),
      filteredValue: tableParams?.filter?.status,
      filterDropdown(props) {
        return <Filter filterProps={props} options={FILTERS.STATUS} />;
      },
    },
    {
      title: 'Upload Date',
      dataIndex: 'createdAt',
      width: '5%',
      ellipsis: true,
      render: (val) => dayjs(val)?.format(defaultDateFormat) ?? '-',
      filterDropdown: ({ confirm, clearFilters }) => (
        <div className="table-range-sorting">
          <RangePicker
            size="middle"
            disabledDate={disabledFutureDates}
            value={selectedDate}
            onChange={(value) => {
              setSelectedDate(value);
            }}
            format="DD/MM/YYYY"
          />
          <div className="filter-footer">
            <Button
              type="link"
              size="small"
              disabled={!selectedDate}
              loading={loading}
              onClick={() => {
                handleDateReset(clearFilters, confirm);
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => handleDateSave(confirm)}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </div>
      ),
      filterIcon: <CalendarOutlined />,
      filteredValue: selectedDate,
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: '5%',
      render: (_, { id }) => (
        <Popconfirm
          placement="topLeft"
          title="Delete"
          description="Are you sure to delete this file?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => deleteAsset({ variables: { where: { id } } })}
          okButtonProps={{ loading: deleteLoading }}
        >
          <DeleteOutlined className="pointer delete-icon" />
        </Popconfirm>
      ),
    },
  ];

  const handleSwitch = (isChecked) => {
    setToggle(isChecked);
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        skip: 0,
      },
    });
  };

  const handleModalClose = () => {
    setIsAlertModal(false);
  };

  const handleSearch = (val) => {
    const search = val?.trim();
    setTableParams({
      ...tableParams,
      filter: {
        ...tableParams?.filter,
        limit: LIMIT,
        skip: 0,
        search,
      },
    });
  };

  return (
    <div className="content-wrapper">
      <Space direction="vertical" className="d-flex" size={4}>
        <div className="d-flex justify-between align-center">
          <h2>
            Contents{' '}
            <Badge
              count={data?.assets?.count}
              showZero
              color="var(--color-primary)"
            />
          </h2>
          <div className="filter-wrapper d-flex gap-8">
            <SearchComponent
              placeholder="Search file Name or URL"
              getData={handleSearch}
            />
            <Button
              onClick={() => setUploadModal(true)}
              icon={<ImportOutlined />}
            >
              Import Files
            </Button>
          </div>
        </div>
        <div>
          <Flex size={32} justify="space-between" align="center">
            <Space>
              <p>Hide processed content</p>
              <Switch size="small" loading={loading} onChange={handleSwitch} />
            </Space>
          </Flex>
        </div>
        <div className="responsive-table">
          <Table
            columns={columns}
            dataSource={dataList}
            pagination={false}
            loading={loading}
            className="content-list-table"
            tableLayout="auto"
            onChange={handleTableChange}
            locale={{
              filterConfirm: 'Save',
              emptyText: 'No content available',
            }}
          />
        </div>
        {data?.assets?.count > dataList?.length && <LoadMore />}
      </Space>
      <UploadModal
        open={uploadModal}
        onCancel={() => setUploadModal(false)}
        fetchData={() => {
          setTableParams({
            ...tableParams,
            filter: {
              ...tableParams?.filter,
              limit: LIMIT,
              skip: 0,
            },
          });
        }}
      />
      <ErrorModal
        open={isAlertOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        callback={() => {
          handleModalClose();
          setUploadModal(true);
        }}
      />
    </div>
  );
}
