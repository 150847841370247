import { Button, Checkbox } from 'antd';
import React from 'react';

const Filter = ({ filterProps, options }) => {
  const { confirm, close, setSelectedKeys, selectedKeys } = filterProps;

  const renderFilterInput = () => (
    <Checkbox.Group
      className="d-flex flex-vertical filter-label"
      options={options}
      onChange={(item) => {
        setSelectedKeys(item);
      }}
      value={selectedKeys}
    />
  );

  return (
    <div className="p-4 d-flex flex-vertical gap-4 filter-container">
      {renderFilterInput()}
      <div className="d-flex gap-2 justify-between filter-btn">
        <Button
          size="small"
          onClick={() => {
            close();
            setSelectedKeys([]);
            confirm();
          }}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            confirm();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Filter;
