import { InboxOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Modal, Spin } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSV_FILE_EXTENSION } from '../../../common/constants';
import { beforeFileUpload } from '../../../common/utils';
import { GET_CSV_UPLOAD_SIGNED_URL } from '../../../modules/auth/graphql/queries';
import { INVITE_USERS } from '../../../modules/users/graphql/mutations';

export default function UploadEmailModal({
  onCancel = () => {},
  fetchData = () => {},
  ...rest
}) {
  const [isUploading, setIsUploading] = useState(false);
  const SAMPLE_CSV_LINK = process.env.REACT_APP_SAMPLE_EMAIL_CSV_LINK;
  const [inviteUsers] = useMutation(INVITE_USERS, {
    onCompleted: () => {
      fetchData();
      onCancel();
    },
    onError: () => {},
  });

  const [getSignedUrl] = useLazyQuery(GET_CSV_UPLOAD_SIGNED_URL, {
    onError: () => {},
  });

  const handleUpload = async (file) => {
    setIsUploading(true);
    getSignedUrl({
      variables: {
        data: {
          fileName: file?.name,
        },
      },
      onCompleted: async ({ getCsvUploadSignedUrl }) => {
        try {
          const response = await axios?.put(
            getCsvUploadSignedUrl?.signedUrl,
            file,
          );
          if (response?.status === 200) {
            inviteUsers({
              variables: {
                data: { fileUrl: getCsvUploadSignedUrl?.key },
              },
            });
          }
        } catch (err) {
          return err;
        } finally {
          setIsUploading(false);
        }
      },
      onError: () => {
        setIsUploading(false);
      },
    });
  };

  const props = {
    name: 'file',
    multiple: false,
    beforeUpload: () => false,
    fileList: [],
    accept: CSV_FILE_EXTENSION,
    onChange: (info) => {
      if (beforeFileUpload(info?.file)) {
        handleUpload(info?.file);
      }
    },
  };

  return (
    <Modal footer={false} title="Upload Files" onCancel={onCancel} {...rest}>
      <Spin spinning={isUploading}>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">
            Support for a single file upload. Only files with the following
            extensions are accepted: CSV.
          </p>
        </Dragger>
      </Spin>
      <p className="text-center">
        <Link to={SAMPLE_CSV_LINK} target="_blank">
          <strong className="text-underline">Click here</strong> to download
          sample CSV file.
        </Link>
      </p>
    </Modal>
  );
}
