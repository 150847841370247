/* COMPANY INFO */
export const COMPANY_INFO = {
  FOOTER_TEXT: `© Logicwind ${new Date().getFullYear()}. All Rights Reserved.`,
  COOKIE_EXPIRY: 525600, // 365 days in minutes,
  PROFILE_UPLOAD_SIZE: 2, // in MB
  FILE_UPLOAD_SIZE: 2, // in MB
};
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY: '/verify',
  AUTHENTICATION: '/authentication',
  CONTENTS: '/contents',
  PROFILE: '/profile',
  USERS: '/users',
  IMPORT_EXPORT: '/import-export',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  CONTENT: 'Contents',
  USERS: 'Users',
  IMPORT_EXPORT: 'Import/Export',
};

/* Authentication */
export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin',
};

export const ROLE_KEYS = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
};

/* Date and time */
export const defaultDateFormat = 'DD/MM/YYYY [|] hh:mm A';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const LIMIT = 10;

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const FILTERS = {
  STATUS: [
    { label: 'Failed', value: 'FAILED' },
    { label: 'Invalid', value: 'INVALID' },
    { label: 'In-Progress', value: 'INPROGRESS' },
    { label: 'Processed', value: 'PROCESSED' },
    { label: 'Unprocessed', value: 'UNPROCESSED' },
  ],
  FILE_TYPES: [
    { label: 'Audio', value: 'AUDIO' },
    { label: 'Pdf', value: 'PDF' },
    { label: 'Video', value: 'VIDEO' },
    { label: 'Website', value: 'WEBSITE' },
  ],
  FILE_STATUS: [
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Processing', value: 'PROCESSING' },
    { label: 'Partially Completed', value: 'PARTIALLY_COMPLETED' },
    { label: 'Failed', value: 'FAILED' },
  ],
  USER_LOGGED_IN_STATUS: [
    { label: 'Invited', value: 'INVITED' },
    { label: 'Logged-in', value: 'LOGGED_IN' },
    { label: 'Expired', value: 'EXPIRED' },
  ],
};

export const PROCESS_STATUS = {
  IN_PROGRESS: 'INPROGRESS',
  PROCESSED: 'PROCESSED',
  FAILED: 'FAILED',
  UNPROCESSED: 'UNPROCESSED',
  INVALID: 'INVALID',
};

export const FILE_TYPES = {
  AUDIO: 'AUDIO',
  PDF: 'PDF',
  VIDEO: 'VIDEO',
  WEBSITE: 'WEBSITE',
};

export const FILE_STATUS = {
  COMPLETED: 'COMPLETED',
  PROCESSING: 'PROCESSING',
  PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
  FAILED: 'FAILED',
};

export const COMMON_ALLOWED_FILE_TYPES = 'image/png, image/jpeg';
export const COMMON_FILE_UPLOAD_TYPES = 'text/csv, application/pdf';
export const CSV_FILE_EXTENSION = 'text/csv';

export const SORT_BY = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const TABLE_SORT_DESC_ORDER = 'descend';

export const DEFAULT_SORT_ORDER = ['ascend', 'descend', 'ascend'];

export const SORT_ON = {
  createdAt: 'createdAt',
};

export const MESSAGE = {
  required: 'This field is required.',
};

export const USER_STATUS = {
  INVITED: 'INVITED',
  LOGGED_IN: 'LOGGED_IN',
  EXPIRED: 'EXPIRED',
};
